import { React, useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Box,
  Paper,
  Stack,
  Card,
  Skeleton,
} from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import TopNavbar from "../../components/top_navbar";
import ManManu from "../../components/main_manu";
import MainFooter from "../../components/main_footer";
import Newsletter from "../../components/newsletter";
import BottomFooter from "../../components/botttom_footer";
import FooterButton from "../../components/footer_button";
import ContactUs from "../../components/ContactUs";
import AdvanceSearchProjects from "./AdvanceSearchProjects";
import { cardstyle } from "../../theme/theme";
import AboutProject from "./AboutProject";
import VideoSection from "./VideoSection";
import AmenitiesFetures from "./AmenitiesFetures";
import FloorPlans from "./FloorPlans";
import Pricing from "./Pricing";
import ImageSlider from "./ImageSlider";
import Neighbourhood from "./Neighbourhood";
import OtherProjectsBySameDeveloper from "./OtherProjectsBySameDeveloper";
import ProjectSubPageHeroSlider from "./ProjectSubPageHeroSlider";
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "aos/dist/aos.css";
import { subheading } from "../../theme/theme";
import axios from "axios";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const SingleProjectDetails = (props) => {
  const { title, id } = useParams();
  const [loading, setLoading] = useState(true);
  const [projectDetails, setProjectDetails] = useState(null);
  const location = useLocation();
  const locationProjectDetails = location.state?.projectDetails;

  useEffect(() => {
    if (locationProjectDetails) {
      setProjectDetails(locationProjectDetails);
      setLoading(false);
    } else {
      fetchProjectDetails();
    }
  }, [title]);

  const fetchProjectDetails = async () => {
    try {
      // console.warn(id);
      const response = await axios.get(
        `https://cityluxedxb.com/cityluxedxb/getProjectsdetails.php`,
        {
          params: {
            title: encodeURIComponent(title),
            id: id,
          },
        }
      );
      setProjectDetails(response.data);
      // console.log("response is", response);
    } catch (error) {
      console.error("Error fetching project details:", error);
    } finally {
      setLoading(false);
    }
  };

  const styles = {
    container: {
      position: "relative",
      width: "100%",
      height: "70vh",
      overflow: "visible",
    },
    filmLayer: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.5)",
      zIndex: 1,
    },
    sliderContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 2,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    background: {
      backgroundSize: "cover",
      width: "100%",
      height: "100%",
      zIndex: 0,
    },

    contactPaper: {
      padding: "20px",
      boxShadow: "none",
      position: "sticky",
      top: "63px",
      marginBottom: 20,
    },
    advancePaper: {
      paddingRight: "20px",
      paddingLeft: "20px",
      marginBottom: "20px",
      boxShadow: "none",
    },
    quickFacts: {
      padding: "20px",

      border: "1px solid #e1d3bc",
      boxShadow: "none",
      top: "20px",
      marginBottom: 20,
    },
  };
  let matches = props.matches;
  let manmanu = {};
  if (matches) {
    if (props.scroll) {
      manmanu = {
        width: "100%",
        py: "0px",
        mt: "-1px",
        bgcolor: "white",
        position: "fixed",
        top: 0,
        zIndex: 100,
        animation: "scrollmanu 0.3s linear",
        "@keyframes scrollmanu": {
          from: {
            py: "10px",
          },
          to: {
            py: "0px",
          },
        },
      };
    } else {
      manmanu = {
        width: "100%",
        py: "10px",
        mt: "-1px",
        bgcolor: "custom.white",
        position: "unset",
        zIndex: 100,
        animation: "scrollmanu2 0.3s linear",
        "@keyframes scrollmanu2": {
          from: {
            py: "0px",
          },
          to: {
            py: "10px",
          },
        },
      };
    }
  } else {
    manmanu = {
      width: "100%",
      bgcolor: "custom.dark4",
    };
  }

  if (loading) {
    return (
      <>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Skeleton
            variant="rectangular"
            width={210}
            height={118}
            style={{ marginTop: "300px", marginLeft: "200px" }}
          />
          <Skeleton
            variant="rectangular"
            width={210}
            height={118}
            style={{ marginTop: "300px", marginLeft: "200px" }}
          />
          <Skeleton
            variant="rectangular"
            width={210}
            height={118}
            style={{ marginTop: "300px", marginLeft: "200px" }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Skeleton
            variant="rectangular"
            width={210}
            height={118}
            style={{ marginTop: "300px", marginLeft: "200px" }}
          />
          <Skeleton
            variant="rectangular"
            width={210}
            height={118}
            style={{ marginTop: "300px", marginLeft: "200px" }}
          />
          <Skeleton
            variant="rectangular"
            width={210}
            height={118}
            style={{ marginTop: "300px", marginLeft: "200px" }}
          />
        </div>
      </>
    );
  }

  if (!projectDetails || !projectDetails.subBanner) {
    return <div>No project details available.</div>;
  }
  return (
    <>
      <Box sx={{ position: "relative", top: 0, left: 0 }}>
        {/* TopNavBar Section */}
        <Box bgcolor={{ xs: "custom.dark", md: "custom.dark" }}>
          <Container maxWidth="lg">
            <TopNavbar matches={matches} />
          </Container>
        </Box>
        {/* NavBar/main-menu section */}
        <Box sx={manmanu}>
          <Container maxWidth="lg">
            <ManManu scroll={props.scroll} color={"dark2"} />
          </Container>
        </Box>
      </Box>
      {/* Main content is starts from here */}
      <Box sx={styles.container}>
        <Box
          className="swiperbox"
          sx={styles.background}
          style={{ width: "100%", height: "70vh" }}
        >
          <Swiper
            direction={"horizontal"}
            autoplay={{ delay: 4000, disableOnInteraction: false }}
            loop={true}
            speed={3000}
            pagination={{ clickable: true }}
            mousewheel={true}
            modules={[Autoplay, Pagination]}
            className="backgroundSwiper"
            style={{ width: "100%", height: "70vh" }}
          >
            {projectDetails.subBanner.map((banner, index) => (
              <SwiperSlide key={index} style={{ padding: "0px" }}>
                <Box
                  sx={{
                    ...styles.background,
                    backgroundImage: `url(${banner.banner_link})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "100%",
                  }}
                ></Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
        {/* Dark transparent film layer */}
        <Box sx={styles.filmLayer}></Box>
        {/* Slider container */}
        <Box sx={styles.sliderContainer}>
          {" "}
          <ProjectSubPageHeroSlider projectDetails={projectDetails} />
        </Box>
      </Box>
      <Container maxWidth="lg">
        <Stack sx={{ padding: "16px 5px" }}>
          {/* <Breadcrumb breadcrumb={["home","projects", projectDetails.project.title,  ]} /> */}
        </Stack>

        <Grid container spacing={2}>
          <Grid item xs={12} md={9} sx={{ marginBottom: "20px" }}>
            <AboutProject projectDetails={projectDetails} />
            <VideoSection projectDetails={projectDetails} />
            <AmenitiesFetures projectDetails={projectDetails} />
            <Neighbourhood projectDetails={projectDetails} />
            <FloorPlans projectDetails={projectDetails} />
            <Pricing projectDetails={projectDetails} />
            <ImageSlider projectDetails={projectDetails} />
            <OtherProjectsBySameDeveloper projectDetails={projectDetails} />
          </Grid>
          {/* Box with multiple sets of content */}

          {/* Second Column (4) */}
          <Grid xs={12} md={3}>
            <Paper style={styles.advancePaper}>
              <Card sx={{ ...cardstyle, mb: "35px", p: "20px" }}>
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  component="div"
                  sx={{ ...subheading }}
                >
                  Advanced Search
                </Typography>
                <AdvanceSearchProjects />
              </Card>{" "}
            </Paper>
            <Paper style={styles.contactPaper}>
              <ContactUs projectDetails={projectDetails} />
            </Paper>
          </Grid>
        </Grid>
      </Container>
      {/* MainFooter Section */}
      <Box sx={{ pt: "50px", backgroundColor: "#2c2c2c" }}>
        <Container maxWidth="lg">
          <Box>
            <MainFooter />
          </Box>
        </Container>
        {/* NewsLetter subscription section */}
        <Box sx={{ borderTop: "0.5px solid #666666", py: "20px", mt: "25px" }}>
          <Container>
            <Newsletter />
          </Container>
        </Box>
      </Box>
      {/* Bottom footer section*/}
      <Box sx={{ backgroundColor: "#272727", py: "15px" }}>
        <Container maxWidth="lg">
          <BottomFooter />
        </Container>
      </Box>
      <FooterButton scroll={props.scroll} />
    </>
  );
};

export default SingleProjectDetails;
